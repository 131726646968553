import styles from './benutzer.module.scss';
import Layout from 'components/layout';
import Wrapper from 'components/Wrapper/Wrapper';
import StickyBar from 'components/StickyBar/StickyBar';
import api from 'api';
import useAuthStore from 'store/useAuth';
import DataTable from 'components/DataTable/DataTable';
import React, { useState, useEffect, useRef } from 'react';

import Container from '@wienenergiegithub/ui-next/src/common/components/Container/Container';
import Button from '@wienenergiegithub/ui-next/src/common/components/Button/Button';

import { Link } from 'gatsby';

const Page = () => {
  const stickyRef = useRef(null);
  const [users, setUsers] = useState();
  const userCan = useAuthStore(state => state.userCan);
  const currentUserCanEdit = userCan('edit user');

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await api.get(`/backend/api/users`);

        setUsers(response.data.data);
        console.log(response.data.data);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    };
    getData();
  }, []);

  return (
    <Wrapper>
      <Layout title="Kunden">
        {currentUserCanEdit && (
          <StickyBar ref={stickyRef}>
            <div className={styles.actions}>
              <Button theme="primary" as={Link} to="/benutzerdaten">
                Neuen Benutzer anlegen
              </Button>
            </div>
          </StickyBar>
        )}
        {users && (
          <Container size="lg">
            <DataTable
              data={users}
              columns={[
                {
                  Header: 'Name',
                  accessor: 'name',
                },
                {
                  accessor: 'action',
                  disableSortBy: true,
                  Cell: ({
                    row: {
                      original: { id },
                    },
                  }) => (
                    <Button
                      as={Link}
                      variant="link"
                      to={`/benutzerdaten?user=${id}`}
                    >
                      Bearbeiten
                    </Button>
                  ),
                },
              ]}
              hasStickyHeader
              offset={{ top: stickyRef.current?.clientHeight }}
            />
          </Container>
        )}
      </Layout>
    </Wrapper>
  );
};

export default Page;
